@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* @apply bg-[#62BC6216]; */
  }
}
@layer components {
  .input {
    @apply block border outline-lime-900 p-3 rounded-md;
  }

  .input-error {
    @apply text-red-600 px-1 text-sm mt-1;
  }

  .button {
    @apply bg-green-700 rounded-3xl text-white hover:opacity-90 active:scale-90 transition duration-150 outline-none;
  }

  .card {
    @apply rounded-3xl shadow-xl h-auto bg-white min-w-min;
  }

  .text-area {
    @apply p-2.5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-1 focus:ring-gray-500 focus:outline-none active:ring-1 active:ring-gray-500;
  }

  .pagination-cell {
    @apply bg-gray-100 p-1 border border-gray-300 disabled:text-gray-400 hover:opacity-80;
  }

  @layer utilities {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
